import { apiClient } from "./httpClientUnauthorized";

const END_POINT = "info";

const getPortalApiInfo = async () => {
  let message = undefined;
  const response = await apiClient.get(`${END_POINT}`).catch(error => {
    message = error.toString();
  });
  return { data: response ? response.data : undefined, message: message };
};

export { getPortalApiInfo };
