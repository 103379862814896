<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { getPortalApiInfo } from "@/services/api/info.api";

onMounted(() => {
  getVersion();
});

const version = ref();

async function getVersion() {
  const response = await getPortalApiInfo();
  console.log("Info getVersion: ", response);
  if (response.data) {
    version.value = response.data.version;
  }
}
</script>

<template>
  <v-container fluid pa-0>
    <v-row>
      <v-col cols="12" class="app-dashboard-overview">
        <v-container fluid py-0>
          <header class="app-welcome-message">
            <p>
              Paris Proof Monitor
            </p>
          </header>

          <v-row v-if="version">
            <v-col>
              Applicatie versie: {{ version }}
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <router-link to="/">Home</router-link>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
